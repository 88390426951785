import SpotifyHandler from './SpotifyHandler.js';
import axios from 'axios';
var $ = require('jquery');
window.jQuery = $;

$(function() {

  $('.js-modal-show').on('click', function (e) {
    e.preventDefault();

    var target = $(this).attr('href');

    $('html').addClass('is-modal-active');

    $(target).addClass('is-visible');
  });

  $('.js-modal-close').on('click', function (e) {
    e.preventDefault();

    $('html').removeClass('is-modal-active');
    $(this).parent('.c-modal').removeClass('is-visible');
  });

  //Show share pop up
  $('.js-share').click(function(event) {
    event.preventDefault();

    var target = $(this).attr('href');

    ga('send', 'event', 'button click', 'Social share');

    openSharePopUp(
      target
      + encodeURIComponent(window.location.href + '&redirect=1'),
      600, 400
    );
  });

  function openSharePopUp(url, width, height) {
    var left   = ($(window).width()  - width)  / 2,
        top    = ($(window).height() - height) / 2,
        opts   = 'status=no' +
                 ', width='  + width  +
                 ', height=' + height +
                 ', top='    + top    +
                 ', left='   + left   +
                 ', toolbar=no, menubar=no, scrollbars=no, location=no, directories=no';
    window.open(url, 'Compartilhar', opts);
  }

  if (document.getElementById('login-button')) {
    document.getElementById('login-button').addEventListener('click', function(e) {
      e.preventDefault();
      // var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      // if (iOS) {
      //   alert('iOS!');
      // }

      ga('send', 'event', 'button click', 'Login com Spotify');

      var client_id = '9f718029713f492e88e4e9829fd38695'; // Your client id
      var redirect_uri = window.location.protocol + '//' + location.host + '/processing.php'; // Your redirect uri

      var state = generateRandomString(16);

      localStorage.setItem(stateKey, state);
      var scope = 'user-library-read playlist-modify-public ugc-image-upload user-top-read';

      var url = 'https://accounts.spotify.com/authorize';
      url += '?response_type=token';
      url += '&client_id=' + encodeURIComponent(client_id);
      url += '&scope=' + encodeURIComponent(scope);
      url += '&redirect_uri=' + encodeURIComponent(redirect_uri);
      url += '&state=' + encodeURIComponent(state);

      window.location = url;
    });
  }

  var stateKey = 'spotify_auth_state';

  /**
   * Obtains parameters from the hash of the URL
   * @return Object
   */
  function getHashParams() {
    var hashParams = {};
    var e, r = /([^&;=]+)=?([^&;]*)/g,
        q = window.location.hash.substring(1);
    while ( e = r.exec(q)) {
       hashParams[e[1]] = decodeURIComponent(e[2]);
    }
    return hashParams;
  }

  /**
   * Generates a random string containing numbers and letters
   * @param  {number} length The length of the string
   * @return {string} The generated string
   */
  function generateRandomString(length) {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  };

  async function callResult(userData, label, playlist) {
    ga('send', 'event', 'button click', label);

    var result = await axios.get('/generate.php', {
      params: {
        userData: userData,
        label: label,
        playlist: playlist
      }
    });
    userData = JSON.parse(userData);
    $('#labelId').val(result.data.id);
    $('#label').val(label);
    $('#userId').val(userData.id);
    $('#playlistId').val(playlist);
    $('#js-call-result').submit();
  }

  var params = getHashParams();

  var access_token = params.access_token,
      state = params.state,
      storedState = localStorage.getItem(stateKey);

  // if (access_token && (state == null || state !== storedState)) {
  //   var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  //   if (iOS) {
  //     alert('Ops, a autenticação falhou porque precisamos do navegador para rotular você! Tente novamente, clicando em Cancelar quando você for direcionado para o app do Spotify.');
  //   } else {
  //     alert('Houve um erro na autenticação!');
  //   }
  //   window.location.replace(location.protocol + '//' + location.host);
  // } else {
    localStorage.removeItem(stateKey);
    if (access_token) {
      var handler = new SpotifyHandler(access_token);

      $(window).on('progressUpdated', function (e) {
        var loader = document.getElementById('js-loader-percentage');
        var currentPercentage = loader.getAttribute('data-load');

        if (e.percentage > currentPercentage) {
          loader.setAttribute('data-load', e.percentage);
        }
      });

      $(window).on('loaderTextChanged', function(e) {
        var loaderText = document.getElementById('js-loader-text');

        loaderText.innerHTML = e.text;
      });

      $(window).on('termsDefined', function (e) {
        console.log(handler.userData.images)
        var avatar = handler.userData.images[0] ?
                      handler.userData.images[0].url : "";
        var userData = JSON.stringify({
          name: handler.userData.display_name,
          id: handler.userData.id,
          image: avatar
        });

        var playlistId = handler.playlistId;

        callResult(userData, e.label, playlistId);
      });
    }
  // }
});
