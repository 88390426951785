var genresMap = {
  "bmore": "Fritado",
  "black": "Black Music",
  "pop": "Popzera",
  "dance pop": "Popzera",
  "rap": "Rapper",
  "pop rap": "Rapper",
  "tropical house": "Fritado",
  "post-teen pop": "Popzera",
  "rock": "Roqueiro",
  "trap music": "Fritado",
  "hip hop": "Rapper",
  "edm": "Fritado",
  "latin": "Latino",
  "southern hip hop": "Rapper",
  "dwn trap": "Fritado",
  "neo mellow": "Indie",
  "alternative rock": "Indie",
  "pop rock": "Roqueiro",
  "latin pop": "Latino",
  "classic rock": "Roqueiro",
  "r&b": "Black Music",
  "album rock": "Roqueiro",
  "indie pop": "Indie",
  "mellow gold": "Indie",
  "alternative metal": "Metaleiro",
  "viral pop": "Popzera",
  "indie rock": "Indie",
  "post-grunge": "Grunge",
  "singer-songwriter": "Popzera",
  "hard rock": "Roqueiro",
  "pop punk": "Punk",
  "indie r&b": "Indie",
  "folk-pop": "Folk",
  "reggaeton": "Latino",
  "soft rock": "Roqueiro",
  "indietronica": "Indie",
  "tropical": "Latino",
  "electro house": "Fritado",
  "indie folk": "Folk",
  "nu metal": "Metaleiro",
  "permanent wave": "Roqueiro",
  "gangster rap": "Rapper",
  "urban contemporary": "Popzera",
  "indie poptimism": "Indie",
  "canadian pop": "Popzera",
  "contemporary country": "Country",
  "country road": "Country",
  "country": "Country",
  "hip pop": "Rapper",
  "house": "Fritado",
  "big room": "Popzera",
  "folk rock": "Folk",
  "rap metal": "Rapper",
  "garage rock": "Grunge",
  "latin hip hop": "Rapper",
  "dirty south rap": "Rapper",
  "rock en espanol": "Roqueiro",
  "chamber pop": "Popzera",
  "funk rock": "Black Music",
  "stomp and holler": "Folk",
  "soul": "Black Music",
  "europop": "Popzera",
  "grupera": "Latino",
  "underground hip hop": "Rapper",
  "modern country rock": "Country",
  "regional mexican": "World Music",
  "roots rock": "Roqueiro",
  "blues-rock": "Blueszeiro",
  "synthpop": "Popzera",
  "alternative dance": "Popzera",
  "dance rock": "Roqueiro",
  "new wave pop": "New Wave",
  "progressive house": "Fritado",
  "emo": "Emo",
  "neo soul": "Black Music",
  "classic funk rock": "Black Music",
  "adult standards": "Popzera",
  "new wave": "New Wave",
  "neo-psychedelic": "Indie",
  "latin alternative": "Latino",
  "banda": "Latino",
  "art rock": "Roqueiro",
  "quiet storm": "Black Music",
  "shimmer pop": "Popzera",
  "psychedelic rock": "Indie",
  "norteno": "Latino",
  "new rave": "Fritado",
  "deep big room": "Popzera",
  "spanish pop": "Popzera",
  "trap latino": "Fritado",
  "west coast rap": "Rapper",
  "teen pop": "Popzera",
  "rap rock": "Rapper",
  "deep indie r&b": "Indie",
  "electronic": "Fritado",
  "motown": "Black Music",
  "progressive electro house": "Fritado",
  "metal": "Metaleiro",
  "deep tropical house": "Fritado",
  "funk": "Black Music",
  "deep pop r&b": "Popzera",
  "escape room": "Popzera",
  "regional mexican pop": "World Music",
  "brostep": "Popzera",
  "crunk": "Rapper",
  "screamo": "Emo",
  "reggaeton flow": "Latino",
  "new romantic": "New Wave",
  "metropopolis": "Popzera",
  "punk": "Punk",
  "deep german hip hop": "Rapper",
  "electronic trap": "Fritado",
  "new americana": "Folk",
  "disco": "Black Music",
  "metalcore": "Metaleiro",
  "swedish pop": "Popzera",
  "pixie": "Emo",
  "indie anthem-folk": "Folk",
  "vapor soul": "Black Music",
  "acoustic pop": "Popzera",
  "hardcore hip hop": "Rapper",
  "focus": "Clássico",
  "trap francais": "Fritado",
  "pop reggaeton": "Latino",
  "german hip hop": "Rapper",
  "chillwave": "Fritado",
  "deep funk carioca": "Funkeiro",
  "folk": "Folk",
  "bass trap": "Fritado",
  "brill building pop": "Popzera",
  "talent show": "Popzera",
  "swedish idol pop": "Popzera",
  "freak folk": "Folk",
  "east coast hip hop": "Rapper",
  "southern rock": "Roqueiro",
  "ranchera": "Latino",
  "jazz blues": "Jazzeiro",
  "christian alternative rock": "Roqueiro",
  "groove metal": "Metaleiro",
  "francoton": "Popzera",
  "modern blues": "Blueszeiro",
  "piano rock": "Roqueiro",
  "g funk": "Black Music",
  "redneck": "Country",
  "vegas indie": "Indie",
  "vocal jazz": "Jazzeiro",
  "worship": "Chill out",
  "boy band": "Popzera",
  "lo-fi": "Indie",
  "soundtrack": "Clássico",
  "country rock": "Country",
  "rock-and-roll": "Roqueiro",
  "british blues": "Blueszeiro",
  "christian music": "Chill out",
  "argentine rock": "Roqueiro",
  "soul blues": "Blueszeiro",
  "ccm": "Chill out",
  "deep pop edm": "Fritado",
  "memphis soul": "Black Music",
  "new jack swing": "Popzera",
  "dance-punk": "Punk",
  "dream pop": "Indie",
  "protopunk": "Punk",
  "glam metal": "Metaleiro",
  "mpb": "Brasileirinho",
  "noise pop": "Popzera",
  "hollywood": "Clássico",
  "electric blues": "Blueszeiro",
  "detroit hip hop": "Rapper",
  "cantautor": "Popzera",
  "sertanejo universitario": "Sertanejo universitário",
  "compositional ambient": "Clássico",
  "deep dutch hip hop": "Rapper",
  "southern soul": "Black Music",
  "britpop": "Popzera",
  "german pop": "Popzera",
  "outlaw country": "Country",
  "melodic metalcore": "Metaleiro",
  "traditional folk": "Folk",
  "korean pop": "World Music",
  "alternative hip hop": "Rapper",
  "deep underground hip hop": "Rapper",
  "deep groove house": "Fritado",
  "christian rock": "Popzera",
  "k-pop": "Popzera",
  "downtempo": "Indie",
  "cumbia pop": "Latino",
  "deep german pop rock": "World Music",
  "anthem worship": "Chill out",
  "chicago soul": "Black Music",
  "deep australian indie": "Indie",
  "indiecoustica": "Indie",
  "underground pop rap": "Rapper",
  "funk metal": "Metaleiro",
  "glam rock": "Roqueiro",
  "funk carioca": "Funkeiro",
  "vapor twitch": "Fritado",
  "traditional country": "Country",
  "tracestep": "Popzera",
  "british invasion": "Roqueiro",
  "disco house": "Black Music",
  "indie psych-rock": "Indie",
  "trip hop": "Fritado",
  "complextro": "Fritado",
  "australian dance": "Fritado",
  "grunge": "Grunge",
  "speed metal": "Metaleiro",
  "scorecore": "Clássico",
  "rockabilly": "Roqueiro",
  "italian pop": "World Music",
  "grime": "Rapper",
  "channel pop": "Popzera",
  "pagode": "Pagodeiro",
  "italian arena pop": "Popzera",
  "melancholia": "Indie",
  "catstep": "Fritado",
  "swedish folk pop": "World Music",
  "mariachi": "Latino",
  "skate punk": "Punk",
  "samba": "Brasileirinho",
  "lilith": "Folk",
  "nu gaze": "Chill out",
  "reggae fusion": "Regueiro",
  "classical": "Clássico",
  "lift kit": "Country",
  "slow core": "Indie",
  "duranguense": "Latino",
  "roots reggae": "Regueiro",
  "video game music": "Popzera",
  "bubblegum pop": "Popzera",
  "etherpop": "Popzera",
  "anti-folk": "Folk",
  "progressive metal": "Metaleiro",
  "dancehall": "Fritado",
  "classify": "Popzera",
  "texas country": "Country",
  "la indie": "Indie",
  "cumbia": "Latino",
  "pop emo": "Emo",
  "australian alternative rock": "Roqueiro",
  "alt-indie rock": "Indie",
  "bossa nova": "Brasileirinho",
  "minimal dub": "Fritado",
  "jazz": "Jazzeiro",
  "reggae": "Regueiro",
  "chillhop": "Rapper",
  "symphonic rock": "Roqueiro",
  "nu jazz": "Popzera",
  "comic": "Popzera",
  "bachata": "Latino",
  "french pop": "Popzera",
  "german techno": "Fritado",
  "power metal": "Metaleiro",
  "madchester": "Roqueiro",
  "otacore": "Emo",
  "progressive rock": "Roqueiro",
  "space rock": "Roqueiro",
  "deep trap": "Fritado",
  "merseybeat": "Roqueiro",
  "deep latin hip hop": "Rapper",
  "danish pop": "Popzera",
  "romantic": "Popzera",
  "hoerspiel": "World Music",
  "sertanejo": "Sertanejo universitário",
  "deep danish pop": "Popzera",
  "trance": "Fritado",
  "alternative country": "Country",
  "jam band": "Roqueiro",
  "candy pop": "Popzera",
  "thrash metal": "Metaleiro",
  "sky room": "Popzera",
  "spanish rock": "Roqueiro",
  "minimal": "Clássico",
  "eurodance": "Fritado",
  "mandopop": "Popzera",
  "finnish dance pop": "Popzera",
  "nashville sound": "Country",
  "taiwanese pop": "World Music",
  "swedish indie rock": "Indie",
  "deep euro house": "Fritado",
  "industrial metal": "Metaleiro",
  "uk funky": "Black Music",
  "lounge": "Chill out",
  "forro": "Brasileirinho",
  "cool jazz": "Jazzeiro",
  "chamber psych": "Indie",
  "bolero": "Latino",
  "brazilian hip hop": "Rapper",
  "french hip hop": "Rapper",
  "deep taiwanese pop": "World Music",
  "salsa": "Latino",
  "deep southern trap": "Fritado",
  "jazz funk": "Jazzeiro",
  "turkish pop": "World Music",
  "shiver pop": "Popzera",
  "post-punk": "Punk",
  "bow pop": "Popzera",
  "progressive trance": "Fritado",
  "finnish pop": "Popzera",
  "alternative emo": "Emo",
  "country dawn": "Country",
  "c-pop": "Popzera",
  "swedish alternative rock": "Roqueiro",
  "nueva cancion": "Latino",
  "danspunk": "Punk",
  "show tunes": "Popzera",
  "merengue": "Latino",
  "punk blues": "Punk",
  "filter house": "Fritado",
  "garage psych": "Indie",
  "brooklyn indie": "Indie",
  "deep swedish hip hop": "Rapper",
  "indie garage rock": "Indie",
  "post-screamo": "Emo",
  "swedish eurodance": "Fritado",
  "power pop": "Popzera",
  "cabaret": "Jazzeiro",
  "vocal house": "Fritado",
  "deep new americana": "Folk",
  "deep cumbia sonidera": "Latino",
  "preverb": "Indie",
  "opm": "Popzera",
  "world worship": "World Music",
  "bubblegum dance": "Popzera",
  "melodic death metal": "Metaleiro",
  "axe": "Axe",
  "uk post-punk": "Punk",
  "industrial rock": "Roqueiro",
  "chanson": "World Music",
  "deep regional mexican": "Latino",
  "post-disco": "Black Music",
  "modern classical": "Clássico",
  "jazz fusion": "Jazzeiro",
  "spanish pop rock": "Popzera",
  "dutch hip hop": "Rapper",
  "classic swedish pop": "Popzera",
  "turkish rock": "Roqueiro",
  "italian hip hop": "World Music",
  "big beat": "Roqueiro",
  "dutch pop": "Popzera",
  "irish rock": "Roqueiro",
  "west coast trap": "Fritado",
  "bebop": "Jazzeiro",
  "post rock": "Roqueiro",
  "cantopop": "Popzera",
  "canadian indie": "Indie",
  "ninja": "Fritado",
  "minimal techno": "Fritado",
  "contemporary post-bop": "Jazzeiro",
  "texas blues": "Blueszeiro",
  "chilean rock": "Roqueiro",
  "dutch house": "Fritado",
  "anthem emo": "Emo",
  "experimental rock": "Roqueiro",
  "chillstep": "Chill out",
  "swedish hip hop": "Rapper",
  "blues": "Blueszeiro",
  "acid jazz": "Jazzeiro",
  "wrestling": "Roqueiro",
  "norwegian pop": "Popzera",
  "melodic hardcore": "Hardcore",
  "progressive bluegrass": "Folk",
  "movie tunes": "Popzera",
  "easy listening": "Popzera",
  "microhouse": "Fritado",
  "norwegian indie": "Indie",
  "vallenato": "World Music",
  "rock gaucho": "Roqueiro gaúcho",
  "rock rs": "Roqueiro gaúcho",
  "traditional blues": "Blueszeiro",
  "french indietronica": "Indie",
  "post-hardcore": "Hardcore",
  "indonesian pop": "World Music",
  "broadway": "Popzera",
  "tech house": "Fritado",
  "hip house": "Fritado",
  "noise rock": "Roqueiro",
  "french rock": "Roqueiro",
  "electro latino": "Latino",
  "fourth world": "Clássico",
  "dreamo": "Emo",
  "uk garage": "Roqueiro",
  "soul jazz": "Jazzeiro",
  "french indie pop": "World Music",
  "sheffield indie": "Indie",
  "australian pop": "Popzera",
  "aussietronica": "Fritado",
  "swedish indie pop": "World Music",
  "latin christian": "Latino",
  "country blues": "Blueszeiro",
  "uplifting trance": "Fritado",
  "reggae rock": "Regueiro",
  "swing": "Latino",
  "progressive post-hardcore": "Hardcore",
  "country gospel": "Gospel",
  "azontobeats": "World Music",
  "lovers rock": "Roqueiro",
  "stoner rock": "Roqueiro",
  "electro swing": "Latino",
  "hard bop": "Jazzeiro",
  "neo classical metal": "Metaleiro",
  "mexican indie": "World Music",
  "indie punk": "Punk",
  "antiviral pop": "Popzera",
  "deep funk": "Black Music",
  "epicore": "Popzera",
  "ambient": "Chill out",
  "nu disco": "Popzera",
  "classic norwegian pop": "World Music",
  "turntablism": "Rapper",
  "industrial": "Roqueiro",
  "gothic metal": "Metaleiro",
  "french reggae": "Regueiro",
  "new age": "Chill out",
  "celtic rock": "Roqueiro",
  "zapstep": "Fritado",
  "latin jazz": "Jazzeiro",
  "gauze pop": "Popzera",
  "german metal": "Metaleiro",
  "stride": "Jazzeiro",
  "hyphy": "Rapper",
  "death metal": "Metaleiro",
  "suomi rock": "Roqueiro",
  "drum and bass": "Fritado",
  "christian hip hop": "Rapper",
  "merengue urbano": "Latino",
  "swedish synthpop": "Popzera",
  "death core": "Roqueiro",
  "electro": "Fritado",
  "deep chiptune": "World Music",
  "ska": "Regueiro",
  "dub": "Regueiro",
  "cumbia villera": "Latino",
  "deep melodic euro house": "Fritado",
  "canadian metal": "Metaleiro",
  "brazilian gospel": "Gospel",
  "pinoy alternative": "World Music",
  "world": "World Music",
  "trova": "World Music",
  "memphis blues": "Blueszeiro",
  "hardcore punk": "Punk",
  "turkish jazz": "Jazzeiro",
  "indie jazz": "Jazzeiro",
  "anime": "World Music",
  "ska punk": "Punk",
  "experimental": "Roqueiro",
  "j-pop": "Popzera",
  "flamenco": "World Music",
  "indie rockism": "Indie",
  "chicago blues": "Blueszeiro",
  "groove room": "Black Music",
  "stomp pop": "Popzera",
  "deep ccm": "Fritado",
  "smooth jazz": "Jazzeiro",
  "louvor": "Black Music",
  "old school hip hop": "Rapper",
  "neue deutsche harte": "World Music",
  "afrobeats": "World Music",
  "german pop rock": "World Music",
  "mambo": "Latino",
  "spanish indie pop": "World Music",
  "latin metal": "Metaleiro",
  "big band": "Jazzeiro",
  "acoustic blues": "Blueszeiro",
  "baroque": "Clássico",
  "stoner metal": "Metaleiro",
  "shoegaze": "Roqueiro",
  "pub rock": "Roqueiro",
  "turkish folk": "World Music",
  "cowboy western": "Country",
  "minimal tech house": "Fritado",
  "icelandic pop": "Popzera",
  "finnish hip hop": "Rapper",
  "norwegian rock": "Roqueiro",
  "cumbia sonidera": "Latino",
  "deep disco house": "Fritado",
  "deep swedish indie pop": "World Music",
  "deep texas country": "Country",
  "delta blues": "Blueszeiro",
  "gospel": "Gospel",
  "new orleans blues": "Blueszeiro",
  "swedish metal": "Metaleiro",
  "relaxative": "Chill out",
  "j-rock": "Roqueiro",
  "classical period": "Clássico",
  "viking metal": "Metaleiro",
  "tejano": "Latino",
  "piedmont blues": "Blueszeiro",
  "liquid funk": "Black Music",
  "electroclash": "Fritado",
  "symphonic metal": "Metaleiro"
};

module.exports = genresMap;

// Allow use of default import syntax in TypeScript
module.exports.default = genresMap;
