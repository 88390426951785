var smuggling = {
  "Blueszeiro": "spotify:track:5MP48dgVWYEwFMGfjACjLk",
  "Folk": "spotify:track:5MP48dgVWYEwFMGfjACjLk",
  "Roqueiro gaúcho": "spotify:track:0pOUOsk3viOWXM9g12eSOq",
  "Jazzeiro": "spotify:track:6zSfxoGmkCNPNXFwmvMhjK",
  "Brasileirinho": "spotify:track:1EFeIEC6c4bSubxNqswAEn",
  "Black Music": [
    "spotify:track:02zeQVm0oNebF9ihQaa0ra",
    "spotify:track:1me3lUOpELNvQ8sS3qIYZp"
  ],
  "Rapper": "spotify:track:02zeQVm0oNebF9ihQaa0ra",
  "Fritado": "spotify:track:7mCW9B7Aw7yawpn6pbiwbV",
  "World Music": "spotify:track:7mCW9B7Aw7yawpn6pbiwbV",

  "Indie": [
    "spotify:track:5MP48dgVWYEwFMGfjACjLk",
    "spotify:track:7mCW9B7Aw7yawpn6pbiwbV"
  ],
  "Alternas": [
    "spotify:track:5MP48dgVWYEwFMGfjACjLk",
    "spotify:track:7mCW9B7Aw7yawpn6pbiwbV"
  ],
  "Indiezinho": [
    "spotify:track:5MP48dgVWYEwFMGfjACjLk",
    "spotify:track:7mCW9B7Aw7yawpn6pbiwbV"
  ],
  "Baladeiro": "spotify:track:02zeQVm0oNebF9ihQaa0ra",
  "da Naite": "spotify:track:02zeQVm0oNebF9ihQaa0ra",
  "das Pixta": "spotify:track:02zeQVm0oNebF9ihQaa0ra",
  "Baixo Astral": "spotify:track:1EFeIEC6c4bSubxNqswAEn",
  "Melancólico": "spotify:track:1EFeIEC6c4bSubxNqswAEn",
  "Depreshow": "spotify:track:1EFeIEC6c4bSubxNqswAEn",
  "Marcha lenta": "spotify:track:1EFeIEC6c4bSubxNqswAEn",
  "Brisado": "spotify:track:1EFeIEC6c4bSubxNqswAEn",
  "Relax": "spotify:track:1EFeIEC6c4bSubxNqswAEn",

  "RoqueiroAnimadão": "spotify:track:68vbn6nZoDqnNm8UdxbmA0",
  "RoqueiroPilhadaço": "spotify:track:68vbn6nZoDqnNm8UdxbmA0",
  "RoqueiroMicareteiro": "spotify:track:68vbn6nZoDqnNm8UdxbmA0",

  "PopzeraMarcha Lenta": "spotify:track:6TG62nAKdnvf88X6jZGpFo",
  "PopzeraBrisado": "spotify:track:6TG62nAKdnvf88X6jZGpFo",
  "PopzeraRelax": "spotify:track:6TG62nAKdnvf88X6jZGpFo",
};

module.exports = smuggling;

// Allow use of default import syntax in TypeScript
module.exports.default = smuggling;
