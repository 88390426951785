var $ = require('jquery');
import axios from 'axios';
var genresMap = require('./genres');
var specialLabels = require('./specialLabels');
var smuggling = require('./smuggling');

class SpotifyHandler {
  constructor(access_token) {

    this.tracksProgress = 0;
    this.playlistsProgress = 0;
    this.artistsProgress = 0;
    this.albumsProgress = 0;
    this.audiosProgress = 0;

    this.playlistCreationProgress = 0;

    this.access_token = access_token;

    this.userData = {};

    this.firstTerm = '';
    this.terms = [];

    this.label = {
      priority: 100,
      term: '',
      seedParams: {}
    };

    this.trackIds = [];
    this.artistsIds = [];
    this.albumIds = [];
    this.explicitCount = 0;
    this.popularity = {
      deep: 0,
      somewhatPopular: 0,
      veryPopular: 0,
      topPopular: 0
    };

    this.playlistCount = 0;
    this.ownedPlaylists = 0;
    this.handledPlaylists = 0;
    this.playlistsTracksUrls = [];

    this.playlistsAreHandled = false;
    this.libraryIsHandled = false;

    this.danceability = {
      high: 0,
      low: 0
    };

    this.valence = {
      high: 0,
      medium: 0,
      low: 0
    };

    this.energy = {
      high: 0,
      low: 0
    };

    this.genres = {};
    this.mainGenre = null;

    this.years = {};
    this.decades = {};

    this.playlistId = '';

    this.topArtists = [];

    this.seedParams = {
      seed_artists: '',
      min_danceability: '0',
      min_popularity: '0',
      max_popularity: '100',
      min_valence: '0',
      max_valence: '100'
    };

    this.playedArtists = {};

    this.priorities = [];

    this.smugglingTracks = [];

    var scope = this;

    Promise.all([
      // this.getPlaylists(),
      // this.getTracks()
      this.getTopTracks()
    ])
    .then(function() {
      scope.goAhead(scope);
    })
    .catch(error => console.error('error: ', error));

  };

  async getPlaylists() {
    var scope = this;
    var playlistPercentage = 0;
    var ready = false;

    var userResponse = await axios.get('https://api.spotify.com/v1/me', {
      headers: {
        'Authorization': 'Bearer ' + this.access_token
      }
    });
    scope.userData = userResponse.data;
    console.log(scope.userData)

    do {
      var response = await axios.get('https://api.spotify.com/v1/me/playlists', {
        params: {
          limit: 50,
          offset: scope.playlistCount
        },
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      })

      scope.playlistCount += response.data.items.length;

      for (var i in response.data.items) {
        if (response.data.items[i].owner.id === scope.userData.id) {
          scope.ownedPlaylists++;
        }
        // To remove followed playlists, move inside if
        scope.playlistsTracksUrls.push(response.data.items[i].tracks.href);
      }
    } while (scope.playlistCount < response.data.total);

    for (var i in scope.playlistsTracksUrls) {
      await scope.getPlaylistTracks(scope.playlistsTracksUrls[i]);

      scope.playlistsProgress = (parseInt(i) / scope.playlistsTracksUrls.length);
      scope.updateProgress();
    }

    return new Promise((resolve, reject) => {
      resolve('resolved getPlaylist');
    });
  };

  handleTopTracksFromResponse(response, scope) {
    for (var i in response.items) {

      if (response.items[i].id === null
        || scope.trackIds.indexOf(response.items[i].id) > -1
      ) {
        continue;
      }

      scope.trackIds.push(response.items[i].id);

      for (var j in response.items[i].artists) {

        // Keep count of tracks per artist
        if (scope.playedArtists[response.items[i].artists[j].id]) {
          scope.playedArtists[response.items[i].artists[j].id]++;
        } else {
          scope.playedArtists[response.items[i].artists[j].id] = 1;
        }

        // Push artist id if not in array
        if (scope.artistsIds.indexOf(response.items[i].artists[j].id) === -1) {
          scope.artistsIds.push(response.items[i].artists[j].id);
        }
      }

      // Push album id if not in array
      if (scope.albumIds.indexOf(response.items[i].album.id) === -1) {
        scope.albumIds.push(response.items[i].album.id);
      }

      // Update track popularity counters
      if (response.items[i].popularity < 20) {
        scope.popularity.deep++;
      } else if (response.items[i].popularity < 50) {
        scope.popularity.somewhatPopular++;
      } else if (response.items[i].popularity < 75) {
        scope.popularity.veryPopular++;
      } else {
        scope.popularity.topPopular++;
      }

      if (response.items[i].explicit) {
        scope.explicitCount++;
      }
    }
  };

  handleTracksFromResponse(response, scope) {
    for (var i in response.items) {

      if (response.items[i].track.id === null
        || scope.trackIds.indexOf(response.items[i].track.id) > -1
      ) {
        continue;
      }

      scope.trackIds.push(response.items[i].track.id);

      for (var j in response.items[i].track.artists) {

        // Keep count of tracks per artist
        if (scope.playedArtists[response.items[i].track.artists[j].id]) {
          scope.playedArtists[response.items[i].track.artists[j].id]++;
        } else {
          scope.playedArtists[response.items[i].track.artists[j].id] = 1;
        }

        // Push artist id if not in array
        if (scope.artistsIds.indexOf(response.items[i].track.artists[j].id) === -1) {
          scope.artistsIds.push(response.items[i].track.artists[j].id);
        }
      }

      // Push album id if not in array
      if (scope.albumIds.indexOf(response.items[i].track.album.id) === -1) {
        scope.albumIds.push(response.items[i].track.album.id);
      }

      // Update track popularity counters
      if (response.items[i].track.popularity < 20) {
        scope.popularity.deep++;
      } else if (response.items[i].track.popularity < 50) {
        scope.popularity.somewhatPopular++;
      } else if (response.items[i].track.popularity < 75) {
        scope.popularity.veryPopular++;
      } else {
        scope.popularity.topPopular++;
      }

      if (response.items[i].track.explicit) {
        scope.explicitCount++;
      }
    }
  };

  async getPlaylistTracks(url) {
    var scope = this;

    var response = await axios.get(url, {
      headers: {
        'Authorization': 'Bearer ' + scope.access_token
      },
    });

   do {
      await scope.handleTracksFromResponse(response.data, scope);

      if (response.data.next) {
        var nextPage = response.data.next;
        var playlistPagination = true;
        do {
          var page = await axios.get(nextPage, {
            headers: {
              'Authorization': 'Bearer ' + scope.access_token
            },
          });

          await scope.handleTracksFromResponse(page.data, scope);

          if (!page.data.next) {
            playlistPagination = false;
          } else {
            nextPage = page.data.next;
          }
        } while (playlistPagination)
      }

      if (++scope.handledPlaylists === scope.playlistCount) {
        scope.playlistsAreHandled = true;
      }
    } while (! scope.playlistsAreHandled);

    return new Promise((resolve, reject) => {
      if (scope.playlistsAreHandled) {
        resolve('resolved getPlaylistTracks');
      }
    });
  };

  async getTracks() {
    var scope = this;

    do {
      var response = await axios.get('https://api.spotify.com/v1/me/tracks', {
        params: {
          limit: 50,
          offset: this.trackIds.length
        },
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      });

      await scope.handleTracksFromResponse(response.data, scope);


      scope.tracksProgress += (scope.trackIds.length / response.data.total);
      scope.updateProgress();

    } while (
      (scope.trackIds.length < response.data.total)
       &&
      (response.data.next !== null)
    );

    return new Promise((resolve, reject) => {
      if (scope.trackIds.length >= response.data.total) {
        resolve('resolved getTracks');
      }
    });
  };

  async getTopTracks() {
    var scope = this;

    var userResponse = await axios.get('https://api.spotify.com/v1/me', {
      headers: {
        'Authorization': 'Bearer ' + this.access_token
      }
    });
    scope.userData = userResponse.data;

    var offset = 0;

    do {
      var response = await axios.get('https://api.spotify.com/v1/me/top/tracks', {
        params: {
          time_range: 'short_term',
          limit: 50,
          offset: offset * 50
        },
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      });

      offset++;

      await scope.handleTopTracksFromResponse(response.data, scope);

      scope.tracksProgress += (scope.trackIds.length / 300);
      scope.updateProgress();
    } while (scope.trackIds.length <= 100 && response.data.next)

    offset = 0;

    do {
      var response = await axios.get('https://api.spotify.com/v1/me/top/tracks', {
        params: {
          time_range: 'medium_term',
          limit: 50,
          offset: offset * 50
        },
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      });

      offset++;

      await scope.handleTopTracksFromResponse(response.data, scope);

      scope.tracksProgress += (scope.trackIds.length / 300);
      scope.updateProgress();
    } while (scope.trackIds.length <= 200 && response.data.next)

    offset = 0;

    do {
      var response = await axios.get('https://api.spotify.com/v1/me/top/tracks', {
        params: {
          time_range: 'long_term',
          limit: 50,
          offset: offset * 50
        },
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      });

      offset++;

      await scope.handleTopTracksFromResponse(response.data, scope);

      scope.tracksProgress += (scope.trackIds.length / 300);
      scope.updateProgress();
    } while (scope.trackIds.length < 300 && response.data.next)

    return new Promise((resolve, reject) => {
      resolve('resolved getTopTracks');
    });
  };

  async goAhead(scope) {

    // Priority 4
    if (scope.label.priority > 4) {
      if (scope.explicitCount / scope.trackIds.length >= 0.3) {
        scope.label.priority = 4.1;
        scope.label.term = (['18+', 'Gangsta', 'Proibidão'][~~(Math.random() * 3)]);

        scope.label.seedParams = {};
      }
    } else {
      scope.priorities.push(-4)
    }

    // Priority 5
    if (scope.label.priority > 5) {
      if (scope.popularity.topPopular / scope.trackIds.length >= 0.18) {
        scope.label.priority = 5.1;
        scope.label.term = (['Topperson','Topzera', 'DJ Jovem Pan'][~~(Math.random() * 3)]);

        scope.label.seedParams = {};
        scope.label.seedParams.min_popularity = 75;
      } else if (scope.popularity.deep / scope.trackIds.length >= 0.25) {
        scope.label.priority = 5.2;
        scope.label.term = (['Indie','Alternas','Indiezinho'][~~(Math.random() * 3)]);

        scope.label.seedParams = {};
        scope.label.seedParams.max_popularity = 20;
      } else {
        scope.priorities.push(-5);
      }
    }

    var promisesFulfilled = false;

    Promise.all([
      scope.getArtists(),
      scope.getAlbums(),
      scope.getAudioFeatures()
    ]).then(async function () {
      if (! scope.label.seedParams.seed_artists) {
        var seedTracks = [];

        var topTracks = await axios.get('https://api.spotify.com/v1/me/top/tracks', {
          params: {
            time_range: 'medium_term',
            limit: 50
          },
          headers: {
            'Authorization': 'Bearer ' + scope.access_token
          }
        });

        //  Seed only top tracks that match the main genre
        for (var track in topTracks.data.items) {

          if (seedTracks.length === 5) {
            break;
          }

          var artistData = await axios.get(
            topTracks.data.items[track].artists[0].href,
            {
              headers: {
                'Authorization': 'Bearer ' + scope.access_token,
                'Content-Type': 'application/json'
              }
            }
          )

          if (artistData.data.genres.indexOf(scope.mainGenre) >= 0) {
            seedTracks.push(topTracks.data.items[track].id)
            topTracks.data.items[track].name
          }
        }

        if (seedTracks.length < 5) {
          seedTracks = [];
          var topTracks = await axios.get('https://api.spotify.com/v1/me/top/tracks', {
            params: {
              time_range: 'long_term',
              limit: 5
            },
            headers: {
              'Authorization': 'Bearer ' + scope.access_token
            }
          });


          for (var track in topTracks.data.items) {
            seedTracks.push(topTracks.data.items[track].id);
          }

        }

        scope.label.seedParams.seed_tracks = seedTracks.join(',');
        scope.label.seedParams.market = 'BR';
      }

      var label = scope.getLabel();
      await scope.createPlaylist(
        'Rotule.Me - ' + label,
        scope.label.seedParams,
        scope.smugglingTracks
      );

      promisesFulfilled = true;
      $(window).trigger($.Event('termsDefined', { label: label }));
      $(window).trigger($.Event('progressUpdated', { percentage: '100%' }));
    });

    return new Promise((resolve, reject) => {
      if (promisesFulfilled) {
        resolve('all done!');
      }
    });
  };

  async getArtists() {
    var offset = 0;
    var scope = this;

    if (scope.trackIds.length < 1) {
      scope.updateProgress();
      scope.changeLoaderText('Não encontramos músicas suficientes');
    }

    do {
      var response = await axios.get('https://api.spotify.com/v1/artists', {
        params: {
          ids: scope.artistsIds.slice(offset, offset + 50).join(',')
        },
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      });

      for (var i in response.data.artists) {
        for (var j in response.data.artists[i].genres) {
          if (scope.genres[response.data.artists[i].genres[j]]) {
            scope.genres[response.data.artists[i].genres[j]]++;
          } else {
            scope.genres[response.data.artists[i].genres[j]] = 1;
          }

          if (scope.mainGenre === null || scope.genres[response.data.artists[i].genres[j]] > scope.genres[scope.mainGenre]) {
            scope.mainGenre = response.data.artists[i].genres[j];
          }
        }
      }

      offset = offset + 50;
      scope.artistsProgress = (offset / scope.artistsIds.length);
      scope.updateProgress();

    } while (offset < scope.artistsIds.length);

    scope.firstTerm = scope.mainGenre;

    // Priority 7
    if (scope.label.priority > 7) {
      if (scope.trackIds.length / scope.artistsIds.length >= 3) {
        scope.label.priority = 7.1;
        scope.label.term = (['Tiete', 'Fanboy', 'Fandom'][~~(Math.random() * 3)]);

        var playsPerArtists = [];

        for (var artist in scope.playedArtists) {
          var obj = {}
          obj.id = artist
          obj.plays = scope.playedArtists[artist]
          playsPerArtists.push(obj);
        };

        playsPerArtists.sort((a, b) => {
          if (a.plays > b.plays) {
            return -1;
          }
          if (a.plays < b.plays) {
            return 1;
          }
          return 0;
        });

        scope.topArtists = playsPerArtists.map(
          (ppa) => ppa.id
        );

        scope.label.seedParams = {};
        scope.label.seedParams.seed_artists = scope.topArtists[0];

      } else if (scope.trackIds.length / Object.keys(scope.genres).length >= 10) {
        scope.label.priority = 7.2;
        scope.label.seedParams = {};
        scope.label.term = (['Fiel', 'Obcecado', 'Conservador'][~~(Math.random() * 3)]);
      } else if (scope.trackIds.length / Object.keys(scope.genres).length <= 2) {
        scope.label.priority = 7.3;
        scope.label.seedParams = {};
        scope.label.term = ([
          'Plural',
          'Ousado',
          'Eclético',
          'Diverso',
          'Descobridorzão',
          'Discovery',
          'Discovery weekly',
          'Random',
          'Shuffle'
        ][~~(Math.random() * 9)]);
      } else if (scope.trackIds.length / scope.artistsIds.length <= 1.3) {
        scope.label.priority = 7.4;
        scope.label.seedParams = {};
        scope.label.term = (['Desbravador', 'Pesquisador', 'Enciclopédico'][~~(Math.random() * 3)]);
      } else {
        scope.priorities.push(-7);
      }
    }

    return new Promise((resolve, reject) => {
      if (offset >= scope.artistsIds.length) {
        resolve('getArtists resolved!');
      }
    });

  };

  async getAlbums() {
    var offset = 0;
    var scope = this;

    do {
      var response = await axios.get('https://api.spotify.com/v1/albums', {
        params: {
          ids: scope.albumIds.slice(offset, offset + 20).join(',')
        },
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      });

      // Update genres counter
      for (var i in response.data.albums) {
        for (var j in response.data.albums[i].genres) {
          if (scope.genres[response.data.albums[i].genres[j]]) {
            scope.genres[response.data.albums[i].genres[j]]++;
          } else {
            scope.genres[response.data.albums[i].genres[j]] = 1;
          }
        }

        // Update decades counter
        if (scope.years[response.data.albums[i].release_date.replace(/^(\d{4}).*$/, '$1')]) {
          scope.years[response.data.albums[i].release_date.replace(/^(\d{4}).*$/, '$1')]++;
        } else {
          scope.years[response.data.albums[i].release_date.replace(/^(\d{4}).*$/, '$1')] = 1;
        }

        if (scope.decades[response.data.albums[i].release_date.replace(/^(\d{3}).*$/, '$1')]) {
          scope.decades[response.data.albums[i].release_date.replace(/^(\d{3}).*$/, '$1')]++;
        } else {
          scope.decades[response.data.albums[i].release_date.replace(/^(\d{3}).*$/, '$1')] = 1;
        }
      }

      offset = offset + 20;

      scope.albumsProgress = (offset / this.albumIds.length);
      scope.updateProgress();

    } while (offset < this.albumIds.length);

    // Priority 2
    if (scope.label.priority > 2) {
      // Set dates label
      if (scope.decades[199] / scope.trackIds.length >= 0.16) {
        scope.label.priority = 2.1;
        scope.label.seedParams = {};
        scope.label.term = (['Noventera', 'Prince of Belair'][~~(Math.random() * 2)]);
      } else if (scope.decades[198] / scope.trackIds.length >= 0.15) {
        scope.label.priority = 2.2;
        scope.label.seedParams = {};
        scope.label.term = (['Oitentera', 'Levada da breca'][~~(Math.random() * 2)]);
      } else if (scope.decades[197] / scope.trackIds.length >= 0.15) {
        scope.label.priority = 2.3;
        scope.label.seedParams = {};
        scope.label.term = (['Setentera', 'Boca de sino'][~~(Math.random() * 2)]);
      } else if (scope.decades[196] / scope.trackIds.length >= 0.15) {
        scope.label.priority = 2.4;
        scope.label.seedParams = {};
        scope.label.term = (['Sessentera', 'Iêiêiê'][~~(Math.random() * 2)]);
      } else if ((scope.years[2016] + scope.years[2017]) / scope.trackIds.length >= 0.30) {
        scope.label.priority = 2.5;
        scope.label.seedParams = {};
        scope.label.term = (['Antenado', 'Djovem', 'Prafrentex'][~~(Math.random() * 3)]);
      } else if ((scope.trackIds.length - scope.decades[200] - scope.decades[201] - scope.decades[199] + scope.years[1990]) / scope.trackIds.length >= 0.6) {
        scope.label.priority = 2.6;
        scope.label.seedParams = {};
        scope.label.term = (['Old School', 'Vintage', 'Das Antiga'][~~(Math.random() * 3)]);
      } else {
        scope.priorities.push(-2);
      }
    }

    return new Promise((resolve, reject) => {
      if (offset >= scope.albumIds.length) {
        resolve();
      }
    });

  };

  async getAudioFeatures() {
    var offset = 0;
    var scope = this;

    do {
      var response = await axios.get('https://api.spotify.com/v1/audio-features', {
        params: {
          ids: scope.trackIds.slice(offset, offset + 100).join(',')
        },
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      });

      for (var i in response.data.audio_features) {
        if (!response.data.audio_features[i]) {
          continue;
        }
        if (response.data.audio_features[i].danceability >= 0.8) {
          scope.danceability.high++;
        } else {
          scope.danceability.low++;
        }

        if (response.data.audio_features[i].valence < 0.2) {
          scope.valence.low++;
        } else if (response.data.audio_features[i].valence < 0.8) {
          scope.valence.medium++;
        } else {
          scope.valence.high++;
        }

        if (response.data.audio_features[i].energy >= 0.3) {
          scope.energy.high++;
        } else {
          scope.energy.low++;
        }
      }

      offset = offset + 100;

      scope.audiosProgress = (offset / scope.trackIds.length);
      scope.updateProgress();
    } while ((offset - 100) < scope.trackIds.length);

    // Priority 3
    if (scope.label.priority > 3) {
      if (scope.danceability.high / scope.trackIds.length >= 0.25) {
        scope.label.priority = 3.1;
        scope.label.term = (['da Naite', 'das Pixta', 'Baladeiro'][~~(Math.random() * 3)]);

        scope.label.seedParams = {};
        scope.label.seedParams.min_danceability = 0.8;
      } else {
        scope.priorities.push(-3);
      }
    }

    // Priority 6
    if (scope.label.priority > 6) {
      if (scope.valence.high / scope.trackIds.length >= 0.3) {
        scope.label.priority = 6.1;
        scope.label.term = (['Animadão', 'Pilhadaço', 'Micareteiro'][~~(Math.random() * 3)]);

        scope.label.seedParams = {};
        scope.label.seedParams.min_valence = 0.8;
      } else if (scope.valence.low / scope.trackIds.length >= 0.3) {
        scope.label.priority = 6.2;
        scope.label.term = (['Baixo Astral', 'Depreshow', 'Melancólico'][~~(Math.random() * 3)]);

        scope.label.seedParams = {};
        scope.label.seedParams.max_valence = 0.8;
      } else if (scope.energy.low / scope.trackIds.length >= 0.4) {
        scope.label.priority = 6.3;
        scope.label.term = (['Marcha Lenta', 'Brisado', 'Relax'][~~(Math.random() * 3)]);

        scope.label.seedParams = {};
        scope.label.seedParams.max_valence = 0.8;
      } else {
        scope.priorities.push(-6);
      }
    }

    return new Promise((resolve, reject) => {
      if (offset >= scope.trackIds.length) {
        resolve('audioFeatures resolved!');
      }
    });
  };

  getLabel() {

    var sortable = [];
    for (var genre in this.genres) {
      sortable.push([genre, this.genres[genre]]);
    }

    sortable.sort(function(a, b) {
      return b[1] - a[1];
    });

    console.log('sorted genres:', sortable);
    var userId = String(this.userData.id);
    if (specialLabels[userId]) {
      var second = specialLabels[userId];
    } else {
      var second = this.label.term;
    }
    if (genresMap[this.firstTerm]) {
      var first = genresMap[this.firstTerm];
    } else {
      var first = this.firstTerm;
    }

    if (smuggling[first+second]) {
      if (typeof smuggling[first+second] == 'string') {
        this.smugglingTracks.push(smuggling[first+second])
      } else {
        for (var i in smuggling[first+second]) {
          this.smugglingTracks.push(smuggling[first+second][i])
        }
      }
    } else if (smuggling[first]) {
      if (typeof smuggling[first] == 'string') {
        this.smugglingTracks.push(smuggling[first])
      } else {
        for (var i in smuggling[first]) {
          this.smugglingTracks.push(smuggling[first][i])
        }
      }
    } else if (smuggling[second]) {
      if (typeof smuggling[second] == 'string') {
        this.smugglingTracks.push(smuggling[second])
      } else {
        for (var i in smuggling[second]) {
          this.smugglingTracks.push(smuggling[second][i])
        }
      }
    }

    return first + ' ' + second;
  };

  async createPlaylist(playlistName, seedParams, smuggling) {
    var scope = this;

    // Remove seed params if there is an artist to seed
    if (seedParams.seed_artists) {
      seedParams = {
        market: 'BR',
        seed_artists: seedParams.seed_artists
      }
    }

    // If smuggling, get less from recommendation
    if (smuggling.length > 0) {
      if (typeof smuggling == 'string') {
        seedParams.limit = 19;
      } else {
        seedParams.limit = 20 - smuggling.length;
      }
    }

    // Create a playlist
    var playlistResponse = await axios.post('https://api.spotify.com/v1/users/' + scope.userData.id + '/playlists',
      {
        name: playlistName,
        public: true
      },
      {
        headers: {
          'Authorization': 'Bearer ' + scope.access_token,
          'Content-Type': 'application/json'
      }
    });

    var playlistUri = playlistResponse.data.tracks.href;
    scope.playlistId = playlistResponse.data.id;

    // Get recommendations
    var recommended = await axios.get('https://api.spotify.com/v1/recommendations', {
      headers: {
        'Authorization': 'Bearer ' + scope.access_token,
        'Content-Type': 'application/json'
      },
      params: seedParams
    });

    var tracksUris = [];

    tracksUris = recommended.data.tracks.map(
      (track) => track.uri
    );

    if (smuggling) {
      if (typeof smuggling == 'string') {
        tracksUris.splice([~~(Math.random() * 20)], 0, smuggling);
      } else {
        for (var track in smuggling) {
          tracksUris.splice([~~(Math.random() * 20)], 0, smuggling[track]);
        }
      }
    }

    scope.playlistCreationProgress = 1;
    scope.updateProgress();

    // Add recommended tracks on the created playlist
    await axios.post(playlistUri,
      {
        uris: tracksUris
      },
      {
        headers: {
          'Authorization': 'Bearer ' + scope.access_token,
          'Content-Type': 'application/json'
      }
    });

    return new Promise((resolve, reject) => {
      resolve();
    });
  };

  updateProgress() {
    var scope = this;

    var weight = 100 / 6;
    var percentage = '';
    var value = Math.round(
      scope.tracksProgress * weight +
      scope.playlistsProgress * weight +
      scope.artistsProgress * weight +
      scope.albumsProgress * weight +
      scope.audiosProgress * weight +
      scope.playlistCreationProgress * weight
    );

    if (isNaN(value)) {
      percentage = 'Erro'
    } else {
      percentage = value + '%';
    }

    $(window).trigger($.Event('progressUpdated', {
      percentage
    }));

  }

  changeLoaderText(text) {
    console.log(text);
    $(window).trigger($.Event('loaderTextChanged', { text }));
  }

};

export default SpotifyHandler;
