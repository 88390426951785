var specialLabels = {
  "12150923771": "Tai",
  "anderson_scherer": "Montanha",
  "22g55eo6kf7q7kfcty5nrhhgy": "Queen",
  "carolcottens": "Eita!",
  "22d2duoyjmgordwmanfgxc7da": "Desconstruidão",
  "12156675508": "Agitador",
  "lucasregio": "Emozera",
  "bragantti": "Rotulador",
  "12157987287": "80 Hits",
  "22pytayb7cjgofs5oyzxfu2da": "Grooveiro",
  "22temhtq6d54y367ohb5efmbq": "Diva",
  "popload": "Loading",
  "22koakrikheq5d4wrdoxz4eji": "Do Sebo",
  "12151870990": "Careca cabeludo",
  "22qm3taoxpawblc67mwjzdxgi": "Visionário",
  "11143641896": "Tripsicomequie",
  "andredip": "curte um friozinho",
  "12157993335": "Moderninho",
  "12187000947": "Modeléte",
  "12152078924": "tr00",
  "schltzz": "Futurista",
  "12156060069": "Desconstruidão",
  "12158251370": "Vanguardista",
  "12142448000": "Esquerdista",
  "1221611191": "Publiça",
  "226h5cj3fakajdkql44ojfpmq": "tr00",
  "22zqk44sc4kme3obdorw7o3ra": "Worldwide",
  "filipecatto": "Divo",
  "22ogfpl4o6aq6c74tq5sceydq": "Diva",
  "victorchio": "Sampleguy",
  "chinisalada": "Esquenta",
  "12155329647": "Influencer",
  "22a2j3q5xxy5ndxi2rufpeotq": "Trendzeras",
  "lucasfresno": "Emozera",
  "12142839768": "Monticoisa",
  "felipekautz": "Grooveiro",
  "12148704798": "Radio",
  "juliasouzalves": "Social",
  "22573e5rzogugmawe6k5hd3ky": "Polêmico",
  "12171748939": "Gigi",
  "contatokim": "Galã",
  "12156091454": "Latinão",
  "marcelojeneci": "Pé na terra",
  "12144039203": "Bananista",
  "12143614380": "Entusiasta",
  "paulomiklosoficial": "Titãnico",
  "12152919155": "Grunge",
  "12143282049": "Divina",
  "12169898700": "Youtuberzera",
  "falcaoclarice": "Poeta",
  "12165930847": "Latino",
  "kondzilla": "Magnata ",
  "linikeroficial": "Disruptora",
  "22besbwifasjo6kypeub4ryna": "Baquetador",
  "11139502368": "Redentor",
  "12147172342": "Loirão",
  "tiagoiorcoficial": "Galãnzero",
  "anittaoficial": "Diva",
  "mariagad%C3%BAoficial": "Diva",
  "mariagadúoficial": "Diva",
  "guilherme-arantes": "Romântico",
  "pablovittar": "Diva",
  "floramatos": "da Quebrada"
};

module.exports = specialLabels;

// Allow use of default import syntax in TypeScript
module.exports.default = specialLabels;
